import React from "react"
import { Helmet } from "react-helmet"

const PageStructuredData = ({
    siteUrl,
    title,
    description,
    pagePath,
    pageName,
    pageTitle,
    pageDescription,
    image,
}) => {
    const pageUrl = `${siteUrl}${pagePath}`

    return (
        <Helmet>
            <script type="application/ld+json">
                {`
            {
              "@context":"https://schema.org",
              "@graph":[
                  {
                    "@type":"WebSite",
                    "@id":"${siteUrl}/#website",
                    "url":"${siteUrl}/",
                    "name":"${title}",
                    "description":"${description}",
                    "inLanguage":"en-US"
                  },
                  ${
                      image
                          ? `{
                    "@type":"ImageObject",
                    "@id":"${pageUrl}#primaryimage",
                    "inLanguage":"en-US",
                    "url":"${siteUrl}${image.src}",
                    "contentUrl":"${siteUrl}${image.src}",
                    "width":${image.width},
                    "height":${image.height}
                  },`
                          : ""
                  }
                  {
                    "@type":"WebPage",
                    "@id":"${pageUrl}#webpage",
                    "url":"${pageUrl}",
                    "name":"${pageTitle}",
                    "isPartOf":{
                        "@id":"${siteUrl}/#website"
                    },
                    ${
                        image
                            ? `"primaryImageOfPage":{
                        "@id":"${pageUrl}#primaryimage"
                    },`
                            : ""
                    }
                    "description":"${pageDescription}",
                    "breadcrumb":{
                        "@id":"${pageUrl}#breadcrumb"
                    },
                    "inLanguage":"en-US",
                    "potentialAction":[
                        {
                          "@type":"ReadAction",
                          "target":[
                              "${pageUrl}"
                          ]
                        }
                    ]
                  },
                  {
                    "@type":"BreadcrumbList",
                    "@id":"${pageUrl}#breadcrumb",
                    "itemListElement":[
                        {
                          "@type":"ListItem",
                          "position":1,
                          "name":"Home",
                          "item":"${siteUrl}/"
                        }${
                            pageName
                                ? `,{
                          "@type":"ListItem",
                          "position":2,
                          "name":"${pageName}"
                        }`
                                : ""
                        }
                    ]
                  }
              ]
            }
          `}
            </script>
        </Helmet>
    )
}

export default PageStructuredData
