import React, { useState } from "react"
import ContactUsForm from "./contact_us_form"

const ContactUsFormWrapper = ({ header, submittedMessage, ...rest }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmit = () => setFormSubmitted(true)

  return formSubmitted ? (
    submittedMessage
  ) : (
    <>
      {header}
      <ContactUsForm onSubmit={handleSubmit} {...rest} />
    </>
  )
}

export default ContactUsFormWrapper