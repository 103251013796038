import React from "react"
import { Link } from "gatsby"
import Footer from "../footer"
import SEO from "../seo"

export default function LegalLayout({ children, pageContext, location }) {
    return (
        <>
            <SEO
                title={pageContext.frontmatter.title}
                description={pageContext.frontmatter.description}
                location={location}
            />

            <div className=" bg-blue-dark">
                <div className="prose max-w-none">
                    <div className="container p-10 mx-auto bg-white">
                        <nav className="mb-6">
                            <Link to="/">Go Home</Link>
                        </nav>

                        <div>{children}</div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
