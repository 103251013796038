// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-campaign-launchpad-terms-of-service-mdx": () => import("./../../../src/pages/legal/campaign-launchpad/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-legal-campaign-launchpad-terms-of-service-mdx" */),
  "component---src-pages-legal-email-governor-terms-of-service-mdx": () => import("./../../../src/pages/legal/email-governor/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-legal-email-governor-terms-of-service-mdx" */),
  "component---src-pages-legal-privacy-policy-mdx": () => import("./../../../src/pages/legal/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-mdx" */),
  "component---src-pages-legal-terms-of-service-mdx": () => import("./../../../src/pages/legal/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-legal-terms-of-service-mdx" */),
  "component---src-pages-products-campaign-approver-js": () => import("./../../../src/pages/products/campaign-approver.js" /* webpackChunkName: "component---src-pages-products-campaign-approver-js" */),
  "component---src-pages-products-campaign-launchpad-js": () => import("./../../../src/pages/products/campaign-launchpad.js" /* webpackChunkName: "component---src-pages-products-campaign-launchpad-js" */),
  "component---src-pages-products-email-governor-js": () => import("./../../../src/pages/products/email-governor.js" /* webpackChunkName: "component---src-pages-products-email-governor-js" */),
  "component---src-pages-products-telequeue-js": () => import("./../../../src/pages/products/telequeue.js" /* webpackChunkName: "component---src-pages-products-telequeue-js" */),
  "component---src-pages-resources-whitepapers-why-waiting-for-sales-to-qualify-is-killing-conversion-rates-mdx": () => import("./../../../src/pages/resources/whitepapers/why-waiting-for-sales-to-qualify-is-killing-conversion-rates.mdx" /* webpackChunkName: "component---src-pages-resources-whitepapers-why-waiting-for-sales-to-qualify-is-killing-conversion-rates-mdx" */),
  "component---src-pages-splash-oracle-eloqua-custom-development-mdx": () => import("./../../../src/pages/splash/oracle-eloqua-custom-development.mdx" /* webpackChunkName: "component---src-pages-splash-oracle-eloqua-custom-development-mdx" */),
  "component---src-pages-splash-oracle-eloqua-technology-consulting-mdx": () => import("./../../../src/pages/splash/oracle-eloqua-technology-consulting.mdx" /* webpackChunkName: "component---src-pages-splash-oracle-eloqua-technology-consulting-mdx" */)
}

