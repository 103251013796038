import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const FooterLink = ({ to, content }) => (
    <Link to={to} className="mx-1 underline">
        {content}
    </Link>
)

const FooterList = ({ title, items }) => (
    <div className="w-full mt-8 first:mt-0 md:mt-0 md:w-auto">
        <b>{title}</b>
        <ul>
            {items.map(({ href, content }, i) => (
                <li key={i}>
                    <Link to={href} className="hover:underline">
                        {content}
                    </Link>
                </li>
            ))}
        </ul>
    </div>
)

export default function Footer() {
    const data = useStaticQuery(graphql`
        query {
            siteYaml {
                footer {
                    copyright
                }
            }
            products: allProductsYaml(sort: { fields: [title], order: [ASC] }) {
                nodes {
                    name
                    title
                }
            }
        }
    `)

    const {
        siteYaml: {
            footer: { copyright },
        },
    } = data

    return (
        <footer className="py-8 text-white bg-blue-dark">
            <div className="container flex flex-wrap px-5 mx-auto md:justify-between">
                <FooterList
                    title="DemandByte"
                    items={[
                        { href: "/#about", content: "Who We Are" },
                        { href: "/#services", content: "What We Do" },
                        { href: "/#contact", content: "Contact Us" },
                    ]}
                />

                <FooterList
                    title="Products"
                    items={data.products.nodes.map(({ name, title }) => ({
                        href: `/products/${name}`,
                        content: title,
                    }))}
                />

                <FooterList
                    title="Services"
                    items={[
                        {
                            href: "/oracle-eloqua-custom-development",
                            content: "Oracle Eloqua Custom Development",
                        },
                        {
                            href: "/oracle-eloqua-technology-consulting",
                            content: "Oracle Eloqua Technology Consulting",
                        },
                    ]}
                />
            </div>

            <div className="container mx-auto mt-12 text-sm text-center md:mt-20">
                <div>{copyright}</div>
                <FooterLink to="/privacy-policy" content="Privacy Policy" />
                <FooterLink to="/terms-of-service" content="Terms of Service" />
                <a className="mx-1 underline" href="/sitemap.xml">
                    Site Map
                </a>
            </div>
        </footer>
    )
}
