import React from "react"
import ContactUsFormWrapper from "../contact_us_form_wrapper"
import { ProductParagraph } from "../shared"

const SplashPageForm = ({ splashPage, formHeader, formMessage }) => {
    return (
        <div className="text-left" id="splash">
            <h3 className="text-xl font-bold font-headers text-red-light">
                {formHeader}
            </h3>
            <ContactUsFormWrapper
                formName={`${splashPage}-contact`}
                theme="dark"
                header={<ProductParagraph content={formMessage} />}
                submitButtonText="Contact Us"
                submittedMessage={
                    <div className="min-h-xs lg:min-h-md">
                        <ProductParagraph content="Thanks for contacting us! Someone will get back to you within 24 hours." />
                    </div>
                }
            />
        </div>
    )
}

export default SplashPageForm
