import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { string, object } from "yup"

const encode = (data) => {
    return Object.keys(data)
        .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
}

const FieldGroup = ({
    name,
    placeholder,
    type = "text",
    component = "input",
    theme = "light",
}) => {
    const Component = component

    const [textColor, borderColor, placeholderColor] =
        theme === "light"
            ? ["text-white", "border-white", "placeholder-gray-500"]
            : ["text-gray-text", "border-gray-light", "placeholder-gray-text"]

    return (
        <div className="mt-10">
            <Field name={name}>
                {({ field, meta: { error, touched } }) => (
                    <div>
                        <Component
                            type={type}
                            {...field}
                            placeholder={placeholder.toUpperCase()}
                            className={`min-w-full pb-1 ${textColor} ${placeholderColor} bg-transparent border-b-4 border-dashed outline-none ${
                                touched && error
                                    ? "border-red-700"
                                    : borderColor
                            } ${Component === "input" && "uppercase"}`}
                            aria-label={placeholder}
                        />
                    </div>
                )}
            </Field>
            <ErrorMessage name={name}>
                {(msg) => (
                    <div className="mt-2 text-sm text-red-700">{msg}</div>
                )}
            </ErrorMessage>
        </div>
    )
}

const initialValues = {
    email: "",
    name: "",
    company: "",
    message: "",
}

const ContactUsForm = ({
    formName,
    onSubmit,
    submitButtonText,
    theme = "light",
    showMessageField = true,
}) => {
    const data = useStaticQuery(graphql`
        query {
            siteYaml {
                contact_us {
                    form {
                        email
                        name
                        company
                        message
                        submit
                    }
                }
            }
        }
    `)

    const {
        siteYaml: {
            contact_us: {
                form: { email, name, company, message, submit },
            },
        },
    } = data

    const validationSchema = object().shape({
        email: string()
            .required()
            .email()
            .label(email)
            .test(
                "businessEmail",
                "Please use a business email address",
                (value) =>
                    !/^.+@(gmail|googlemail|hotmail|yahoo|aol)\..+$/.test(value)
            ),
        name: string().required().label(name),
        company: string().required().label(company),
        message: string().max(5000).label(message),
    })

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    fetch("/", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: encode({ "form-name": formName, ...values }),
                    })
                        .then(() => {
                            setSubmitting(false)
                            onSubmit()
                        })
                        .catch((error) => alert(error))
                }}
            >
                {({ isSubmitting, isValid, submitCount }) => (
                    <Form
                        netlify-honeypot="hpb-field"
                        netlify="true"
                        name={formName}
                    >
                        <div className="hidden">
                            <input name="hpb-field" />
                        </div>
                        <FieldGroup
                            name="email"
                            type="email"
                            placeholder={email}
                            theme={theme}
                        />
                        <FieldGroup
                            name="name"
                            placeholder={name}
                            theme={theme}
                        />
                        <FieldGroup
                            name="company"
                            placeholder={company}
                            theme={theme}
                        />

                        {showMessageField && (
                            <FieldGroup
                                name="message"
                                component="textarea"
                                placeholder={message}
                                theme={theme}
                            />
                        )}

                        <div className="text-center">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`inline-block w-3/6 p-2 mx-auto mt-6 font-normal text-white uppercase transition duration-200 ease-in-out bg-blue-dark border-2 rounded-sm md:w-2/6 font-headers border-blue-light hover:bg-blue-light ${
                                    submitCount === 1 &&
                                    !isValid &&
                                    "animation-wobble"
                                }`}
                                aria-label="Submit"
                            >
                                {submitButtonText || submit}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ContactUsForm
