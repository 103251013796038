import React, { Fragment, useEffect, useState } from "react"
import Img from "gatsby-image"
import { Link as BaseGatsbyLink } from "gatsby"
import ReactMarkdown from "react-markdown"
import { MDXProvider } from "@mdx-js/react"

export const BiDownArrowAlt = (props) => (
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        height="1em"
        width="1em"
        {...props}
    >
        <path d="M18.707 12.707l-1.414-1.414L13 15.586V6h-2v9.586l-4.293-4.293-1.414 1.414L12 19.414z" />
    </svg>
)

export const Header = ({ content, className = "" }) => (
    <h1 className={`text-4xl font-bold uppercase font-headers ${className}`}>
        {content}
    </h1>
)

export const SubHeader = ({ content, className = "" }) => (
    <h2
        className={`my-5 text-xl font-semibold uppercase font-headers ${className}`}
    >
        {content}
    </h2>
)

export const Paragraph = ({ content, children, className = "" }) => (
    <p
        className={`py-4 text-xl font-normal leading-6 lg:leading-7 font-body ${className}`}
    >
        {content || children}
    </p>
)

export const Button = ({ href, content, className = "", ...rest }) => (
    <a
        href={href}
        className={`inline-block p-2 mt-6 text-xl font-bold text-white uppercase transition duration-500 ease-in-out border-2 rounded-sm font-headers bg-red-light border-blue-light hover:bg-blue-light ${className}`}
        {...rest}
    >
        {content}
    </a>
)

export const Link = ({ href, content, ...rest }) => (
    <a
        href={href}
        className="transition duration-200 ease-in-out text-blue-light hover:text-red-light"
        {...rest}
    >
        {content}
    </a>
)

export const GatsbyLink = ({ to, content, ...rest }) => (
    <BaseGatsbyLink
        to={to}
        className="transition duration-200 ease-in-out text-blue-light hover:text-red-light"
        {...rest}
    >
        {content}
    </BaseGatsbyLink>
)

export const DynamicLink = ({ href, content, children, ...rest }) =>
    href.match(/^http/) ? (
        <a href={href} {...rest}>
            {content || children}
        </a>
    ) : (
        <BaseGatsbyLink to={href} {...rest}>
            {content || children}
        </BaseGatsbyLink>
    )

export const ProductHeading = ({ title, logoFixed, description }) => (
    <>
        <ProductTitle title={title} logoFixed={logoFixed} />
        <hr className="w-1/2 mx-auto mt-1 border-gray-300 lg:w-1/3" />
        <ProductDescription description={description} />
    </>
)

const ProductTitle = ({ title, logoFixed }) => (
    <h1 className="text-2xl font-medium text-center stroke-current stroke-2 lg:text-4xl font-headers">
        <Img fixed={logoFixed} className="mr-2 align-middle" alt="" />
        <span>{title}</span>
        <small className="text-sm font-normal align-text-top">™</small>
    </h1>
)

const ProductDescription = ({ description }) => (
    <h2 className="my-4 text-base font-medium text-center text-blue-700 font-headers">
        {description}
    </h2>
)

export const ProductScreenShot = ({ fluid }) => (
    <div className="w-full mx-auto my-6 border-4 border-blue-300 md:w-10/12">
        <Img fluid={fluid} />
    </div>
)

export const ProductHeader = ({ content }) => (
    <h3 className="mb-2 text-xl font-bold font-headers text-blue-light">
        {content}
    </h3>
)

export const ProductSubHeader = ({ content }) => (
    <strong className="text-lg font-bold font-body text-blue-dark">
        {content}
    </strong>
)

export const ProductParagraph = ({ content, className = "" }) => (
    <p className={`mt-6 font-body ${className}`}>{content}</p>
)

export const ProductBullets = ({ bullets }) => (
    <>
        {bullets.map(({ header, value, description }, i) => {
            return (
                <div key={i} className="mt-6 text-left">
                    {header && <ProductHeader content={header} />}
                    {value && <ProductSubHeader content={value} />}
                    {description && <p className="font-body">{description}</p>}
                </div>
            )
        })}
    </>
)

export const ProductButton = ({ ...rest }) => (
    <Button className="w-full mx-auto md:w-2/3 md:mx-8 lg:w-2/6" {...rest} />
)

export const ProductButtons = ({ appCloudLink }) => (
    <div className="text-center">
        <ProductButton href="#demo" content="Request a Demo" />
        <ProductButton
            href={appCloudLink}
            content="Get it on App Cloud"
            target="_blank"
            rel="noopener noreferrer"
        />
    </div>
)

export const ProductUseCase = ({
    useCase: { title, problem, solution },
    screenShotFluid,
}) => {
    return (
        <div className="flex flex-wrap items-stretch p-4 mt-8 bg-gray-300 xl:p-20 lg:mt-20 xl:-mx-40">
            <div className="w-full text-left lg:w-1/2">
                <ProductHeader content={title} />
                <ProductParagraph
                    content={
                        <>
                            <strong>Objective: </strong>
                            {problem}
                        </>
                    }
                />
                <ProductParagraph
                    content={
                        <>
                            <strong>Strategy: </strong>
                            {solution}
                        </>
                    }
                />
            </div>
            <div className="w-full lg:w-1/2">
                <div className="flex items-center justify-center h-full">
                    <ProductScreenShot fluid={screenShotFluid} />
                </div>
            </div>
        </div>
    )
}

export const ProductProcess = ({ process, icons }) => {
    return (
        <div className="max-w-lg mx-auto mt-8">
            {process.map(({ step }, i) => {
                return (
                    <Fragment key={i}>
                        <div className="flex flex-wrap items-center justify-center lg:flex-no-wrap">
                            <div className="lg:mr-10 lg:flex-initial">
                                <Img fixed={icons[i].childImageSharp.fixed} />
                            </div>
                            <p className="text-center lg:text-left lg:flex-grow">
                                {step}
                            </p>
                        </div>
                        {i < process.length - 1 && (
                            <BiDownArrowAlt
                                className="mx-auto mt-2 text-6xl text-gray-400"
                                type="solid"
                            />
                        )}
                    </Fragment>
                )
            })}
        </div>
    )
}

const ProductTestimonial = ({ quote, client, visible }) => {
    return (
        <div
            className="animation-back-in-left transition-all duration-150 linear"
            style={{ display: visible ? "block" : "none", minHeight: "12rem" }}
        >
            <span
                className="absolute hidden md:inline"
                style={{ fontSize: "6rem", lineHeight: "6rem" }}
            >
                ‟
            </span>
            <span
                className="absolute hidden md:inline bottom-0 right-0"
                style={{ fontSize: "6rem", lineHeight: "6rem" }}
            >
                ”
            </span>
            <blockquote className="pt-2 md:px-16 md:pt-10">{quote}</blockquote>

            <strong className="block mt-2 text-right clear-both md:pr-20">
                {client}
            </strong>
        </div>
    )
}

export const ProductTestimonials = ({ testimonials }) => {
    const testimonialCount = testimonials.length
    const [testimonialIndex, setTestimonialIndex] = useState(0)

    useEffect(() => {
        if (testimonialCount < 2) return

        const interval = setInterval(
            () =>
                setTestimonialIndex(
                    testimonialIndex + 1 == testimonialCount
                        ? 0
                        : testimonialIndex + 1
                ),
            10000
        )
        return () => clearInterval(interval)
    }, [testimonialCount, testimonialIndex, setTestimonialIndex])

    return (
        <div className="flex items-center p-4 mt-8 bg-red-light xl:p-10 lg:mt-20 xl:-mx-40 text-white">
            <div className="w-full text-left text-lg relative">
                <div>
                    <strong>What Our Clients Are Saying</strong>
                </div>
                {testimonials.map(({ quote, client }, i) => (
                    <ProductTestimonial
                        key={i}
                        quote={quote}
                        client={client}
                        visible={i == testimonialIndex}
                    />
                ))}
                {testimonialCount > 1 && (
                    <div className="flex justify-center gap-3 mt-4">
                        {testimonials.map((_, i) => (
                            <span
                                key={i}
                                onClick={() => setTestimonialIndex(i)}
                                className={`w-2 h-2 inline-block rounded-full bg-white duration-75 transition-all cursor-pointer ${
                                    i == testimonialIndex
                                        ? "animate-pulse"
                                        : "bg-opacity-50"
                                }`}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export const ResourceTeaser = ({
    thumbnail,
    content,
    href,
    buttonText,
    target = "",
}) => {
    return (
        <div className="flex flex-wrap items-center justify-center p-4 mt-12 text-white md:p-6 md:flex-no-wrap md:justify-between bg-blue-light">
            <div>
                <DynamicLink href={href} target={target}>
                    <Img fixed={thumbnail} alt="Whitepaper Cover Image" />
                </DynamicLink>
            </div>
            <div className="p-2 text-center md:p-6">
                <p
                    className="text-lg text-left"
                    dangerouslySetInnerHTML={{ __html: content }}
                />

                <DynamicLink
                    className="inline-block w-4/6 p-2 mx-auto mt-6 font-normal text-white uppercase transition duration-200 ease-in-out border-2 rounded-sm bg-blue-dark md:w-3/6 font-headers border-blue-dark hover:border-white"
                    href={href}
                    target={target}
                >
                    {buttonText}
                </DynamicLink>
            </div>
        </div>
    )
}

const markdownRenderers = {
    paragraph: "span",
    link: (props) => {
        const { href, children } = props
        return <GatsbyLink to={href} content={children} />
    },
}

export const MarkdownRenderer = ({ children }) => {
    return (
        <ReactMarkdown renderers={markdownRenderers}>{children}</ReactMarkdown>
    )
}

export const HeaderWithThumbnail = ({ thumbnail, thumbnailAlt, header }) => {
    return (
        <div className="flex flex-wrap items-center justify-center md:justify-between md:flex-no-wrap md:text-right">
            {/* Thumbnail */}
            <div>{<Img fixed={thumbnail} alt={thumbnailAlt} />}</div>

            {/* Header */}
            <div className="lg:w-10/12">
                <SubHeader
                    content={header}
                    className="md:text-2xl lg:text-4xl"
                />
            </div>
        </div>
    )
}

const contentWithFormComponents = {
    p: (props) => (
        <p
            className="my-4 text-xl font-normal leading-6 lg:leading-7 font-body"
            {...props}
        />
    ),
    li: (props) => (
        <li
            className="ml-4 text-xl font-normal list-disc list-inside font-body"
            {...props}
        />
    ),
    a: ({ href, children, ...rest }) => (
        <GatsbyLink to={href} content={children} {...rest} />
    ),
}

export const ContentWithForm = ({ content, form }) => {
    return (
        <div className="flex flex-wrap justify-between lg:mt-4">
            {/* Content */}
            <div className="w-full lg:w-5/12">
                <MDXProvider components={contentWithFormComponents}>
                    {content}
                </MDXProvider>
            </div>

            {/* Form */}
            <div className="w-full mt-10 lg:bg-gray-300 lg:px-12 lg:py-6 lg:w-6/12 lg:mt-0">
                {form}
            </div>
        </div>
    )
}

export const dasherize = (name) => name.toLocaleLowerCase().replace(/ /g, "-")
