import React from "react"
import { HeaderWithThumbnail, ContentWithForm, dasherize } from "../shared"
import LandingPageLayout from "./landing_page_layout"
import SEO from "../seo"
import ResourceForm from "../resources/resource_form"



export default function WhitepaperLayout({ children, pageContext, data, location }) {
    const {
        frontmatter: { name, title, description },
    } = pageContext

    const whitepaperName = dasherize(name)
    const whitepaperUrl = `/resources/${whitepaperName}.pdf`

    const {src, width, height} = data.thumbnail.childImageSharp.fixed

    return (
        <LandingPageLayout>
            <SEO title={title} description={description} location={location} image={{src, width, height}} />

            <HeaderWithThumbnail
                thumbnail={data.thumbnail.childImageSharp.fixed}
                thumbnailAlt={`Thumbnail of the white paper: ${name}`}
                header={title}
            />

            <ContentWithForm
                content={children}
                form={
                    <ResourceForm
                        formMessage="Fill out the form below to get instant access to the white paper."
                        resourceName={whitepaperName}
                        resourceUrl={whitepaperUrl}
                    />
                }
            />
        </LandingPageLayout>
    )
}
