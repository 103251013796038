import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import PageStructuredData from "./seo/page_structured_data"

export const OrganizationStructuredData = ({
    siteUrl,
    title,
    contactPhone,
}) => {
    return (
        <Helmet>
            <script type="application/ld+json">
                {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${siteUrl}",
          "name": "${title}",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "${contactPhone}",
            "contactType": "Customer Support"
          }
        }
      `}
            </script>
        </Helmet>
    )
}

export const AppStructuredData = ({ name, description, ratingCount, rating }) => {
    return (
        <Helmet>
            <script type="application/ld+json">
                {`
          {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "${name}",
            "applicationCategory": "BusinessApplication",
            "description": "${description}",
            "browserRequirements": "Requires JavaScript. Requires HTML5.",  
            "operatingSystem": "All",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "${rating}",
                "ratingCount": "${ratingCount}"
            }
          }
        `}
            </script>
        </Helmet>
    )
}

export default function SEO({ title, description, children, location, image }) {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    name
                    title
                    siteUrl
                    description
                }
            }
            socialImage: file(relativePath: { eq: "images/social-image.png" }) {
                childImageSharp {
                    fixed(width: 1200, height: 628) {
                        src
                    }
                }
            }
        }
    `)

    const {
        site: {
            siteMetadata: {
                name,
                title: siteTitle,
                siteUrl,
                description: siteDescription,
            },
        },
        socialImage: {
            childImageSharp: {
                fixed: { src: socialImageSrc },
            },
        },
    } = data

    const pageTitle = title ? `${title} | ${name}` : siteTitle
    const pageDescription = description ? description : siteDescription
    const socialImageUrl = siteUrl + socialImageSrc


    return (
        <>
            <Helmet>
                {/* Primary Meta Tags  */}
                <title>{pageTitle}</title>
                <meta name="title" content={pageTitle} />
                <meta name="description" content={pageDescription} />

                {/* Open Graph / Facebook  */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={siteUrl} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content={socialImageUrl} />

                {/* Twitter  */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={siteUrl} />
                <meta property="twitter:title" content={pageTitle} />
                <meta
                    property="twitter:description"
                    content={pageDescription}
                />
                <meta property="twitter:image" content={socialImageUrl} />
                <meta property="twitter:image:alt" content={pageTitle} />

                {/* Favicons */}
                <link
                    rel="apple-touch-icon"
                    sizes="57x57"
                    href={`${siteUrl}/apple-touch-icon-57x57.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="114x114"
                    href={`${siteUrl}/apple-touch-icon-114x114.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="72x72"
                    href={`${siteUrl}/apple-touch-icon-72x72.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="144x144"
                    href={`${siteUrl}/apple-touch-icon-144x144.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="60x60"
                    href={`${siteUrl}/apple-touch-icon-60x60.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href={`${siteUrl}/apple-touch-icon-120x120.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href={`${siteUrl}/apple-touch-icon-76x76.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href={`${siteUrl}/apple-touch-icon-152x152.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${siteUrl}/favicon-196x196.png`}
                    sizes="196x196"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${siteUrl}/favicon-96x96.png`}
                    sizes="96x96"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${siteUrl}/favicon-32x32.png`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${siteUrl}/favicon-16x16.png`}
                    sizes="16x16"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${siteUrl}/favicon-128.png`}
                    sizes="128x128"
                />
                <meta name="application-name" content="DemandByte" />
                <meta name="msapplication-TileColor" content="#0D131D" />
                <meta
                    name="msapplication-TileImage"
                    content={`${siteUrl}/mstile-144x144.png`}
                />
                <meta
                    name="msapplication-square70x70logo"
                    content={`${siteUrl}/mstile-70x70.png`}
                />
                <meta
                    name="msapplication-square150x150logo"
                    content={`${siteUrl}/mstile-150x150.png`}
                />
                <meta
                    name="msapplication-wide310x150logo"
                    content={`${siteUrl}/mstile-310x150.png`}
                />
                <meta
                    name="msapplication-square310x310logo"
                    content={`${siteUrl}/mstile-310x310.png`}
                />
            </Helmet>

            <PageStructuredData
                siteUrl={siteUrl}
                title={siteTitle}
                description={siteDescription}
                pagePath={location.pathname}
                pageName={title}
                pageTitle={pageTitle}
                pageDescription={pageDescription}
                image={image}
            />

            {children}
        </>
    )
}
