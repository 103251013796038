import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LandingPageLayout from "./landing_page_layout"
import { HeaderWithThumbnail, ContentWithForm, dasherize } from "../shared"
import SEO from "../seo"
import SplashPageForm from "../splash/splash_page_form"

export default function SplashPageLayout({ children, pageContext, location }) {
    const data = useStaticQuery(graphql`
        query {
            eloquaLogo: file(
                relativePath: {
                    eq: "images/technology_logos/oracle-eloqua.png"
                }
            ) {
                childImageSharp {
                    fixed(height: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const {
        frontmatter: { title, description, formMessage },
    } = pageContext

    const splashPageName = dasherize(title)

    const {src, width, height} = data.eloquaLogo.childImageSharp.fixed

    return (
        <LandingPageLayout>
            <SEO title={title} description={description} location={location} image={{src, width, height}}/>

            <HeaderWithThumbnail
                thumbnail={data.eloquaLogo.childImageSharp.fixed}
                thumbnailAlt="Oracle Eloqua logo"
                header={title}
            />

            <ContentWithForm
                content={children}
                form={
                    <SplashPageForm
                        splashPage={splashPageName}
                        formHeader='Contact Us'
                        formMessage={formMessage}
                    />
                }
            />
        </LandingPageLayout>
    )
}
