import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Footer from "../footer"
import FontLoader from "../font_loader"

export default function LandingPageLayout({ children }) {
    const {
        logo: {
            childImageSharp: { fixed: logoFixed },
        },
    } = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "images/logo-light.png" }) {
                childImageSharp {
                    fixed(height: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div className="min-h-screen py-2 bg-blue-dark">
            <FontLoader preload={["montserrat-600", "raleway-400"]} />

            <div className="w-full pb-2 pl-10">
                <Link to="/">
                    <Img fixed={logoFixed} />
                </Link>
            </div>

            <div className="w-full bg-white">
                <div className="container px-4 py-12 mx-auto">{children}</div>
            </div>
            <Footer />
        </div>
    )
}
