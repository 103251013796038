import React from "react"
import { Helmet } from "react-helmet"

import montserrat300 from "typeface-montserrat/files/montserrat-latin-300.woff2"
import montserrat400 from "typeface-montserrat/files/montserrat-latin-400.woff2"
import montserrat500 from "typeface-montserrat/files/montserrat-latin-500.woff2"
import montserrat600 from "typeface-montserrat/files/montserrat-latin-600.woff2"
import montserrat700 from "typeface-montserrat/files/montserrat-latin-700.woff2"
import raleway300 from "typeface-raleway/files/raleway-latin-300.woff2"
import raleway400 from "typeface-raleway/files/raleway-latin-400.woff2"
import raleway700 from "typeface-raleway/files/raleway-latin-700.woff2"

const FONTS = [
    montserrat300,
    montserrat400,
    montserrat500,
    montserrat600,
    montserrat700,
    raleway300,
    raleway400,
    raleway700,
]

const FontLoader = ({ preload }) => {
    return (
        <Helmet>
            {FONTS.map((font, i) =>
                // Load the font if its included in the array of
                // fonts to preload.
                preload.some((preloadFont) =>
                    // Note: replacing 'latin-' for ease of use.
                    font.replace("latin-", "").includes(preloadFont)
                ) ? (
                    <link
                        rel="preload"
                        as="font"
                        type="font/woff2"
                        crossOrigin="anonymous"
                        href={font}
                        key={i}
                    />
                ) : null
            )}
        </Helmet>
    )
}

export default FontLoader
