import React from "react"
import ContactUsFormWrapper from "../contact_us_form_wrapper"
import { ProductParagraph, Link } from "../shared"

const ResourceForm = ({ resourceName, formMessage, resourceUrl }) => {
    return (
        <div className="text-left" id="resource">
            <h3 className="text-xl font-bold font-headers text-red-light">
                Download Now
            </h3>
            <ContactUsFormWrapper
                formName={`${resourceName}-download`}
                theme="dark"
                header={<ProductParagraph content={formMessage} />}
                submitButtonText="Download"
                submittedMessage={
                    <div className="min-h-xs lg:min-h-md">
                        <ProductParagraph
                            content={
                                <>
                                    Thanks for downloading! You can access the
                                    white paper here:{" "}
                                    <Link
                                        href={resourceUrl}
                                        target="_blank"
                                        content="Download"
                                    />
                                    .
                                </>
                            }
                        />
                    </div>
                }
                showMessageField={false}
            />
        </div>
    )
}

export default ResourceForm
